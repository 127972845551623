import IconButton from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import makeStyles from "@material-ui/styles/makeStyles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppLayout from "../../components/AppLayout/index";
import ErrorDialog from "../../components/ErrorDialog";
import AddUserForm, { SignUpRequestData } from "../../components/Forms/AddUser";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import EnhancedTable from "../../components/Table/index";
import EnhancedTableRow from "../../components/Table/TableRow/index";
import { useAccountUsersService } from "../../services/account-users";
import { useUserService } from "../../services/user";
import * as style from "./style.module.scss";

const useStyles = makeStyles(() => ({
    modal: {
        overflow: "scroll",
    },
}));

const UserManagement = (): JSX.Element => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const { users, addUser, deleteUser, loading, error, resetError } =
        useAccountUsersService(search);
    const { user } = useUserService();
    const [busy, setBusy] = useState(false);
    const classes = useStyles();

    const headCells = [
        // {
        //     disablePadding: false,
        //     id: "id",
        //     label: t("UserId"),
        //     numeric: false,
        //     sortable: false,
        // },
        {
            disablePadding: false,
            id: "name",
            label: t("Name"),
            numeric: false,
            sortable: true,
        },
        {
            disablePadding: false,
            id: "email",
            label: t("E-Mail"),
            numeric: false,
            sortable: false,
        },
        // {
        //     disablePadding: false,
        //     id: "adminRights",
        //     label: t("AdminRights"),
        //     numeric: true,
        //     sortable: false,
        // },
        {
            disablePadding: false,
            id: "delete",
            label: t("Delete"),
            numeric: true,
            sortable: false,
        },
    ];

    const handleDeleteUser = (rowId: string) => {
        deleteUser(rowId);
    };

    const openAddUserDialog = () => {
        setOpenDialog(true);
    };

    const closeAddUserDialog = () => {
        setOpenDialog(false);
    };

    const handleUserAdd = async (data: SignUpRequestData) => {
        try {
            setBusy(true);
            await addUser(data);
            setBusy(false);
            setOpenDialog(false);
        } catch (error) {
            setOpenDialog(false);
            setBusy(false);
        }
    };

    return (
        <>
            <AppLayout currentPage={t("User Management")}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <TextField
                            id="search"
                            value={search}
                            variant="outlined"
                            label={t("Search for userID or Name")}
                            onChange={event => setSearch(event.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Loader loading={loading}>
                            <EnhancedTable headCells={headCells}>
                                {users?.map((row, index) => {
                                    return (
                                        <EnhancedTableRow key={index}>
                                            {/* <TableCell align="left">
                                                {row.id}
                                            </TableCell> */}
                                            <TableCell align="left">
                                                {row.firstName} {row.lastName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.email}
                                            </TableCell>
                                            {/* <TableCell align="right">
                                                <Switch
                                                    checked={false}
                                                    onChange={() => {
                                                        // TODO: Add admin toggle
                                                        console.log(
                                                            "Add admin toggle",
                                                        );
                                                    }}
                                                />
                                            </TableCell> */}
                                            <TableCell align="right">
                                                {user?.email !== row.email && (
                                                    <IconButton
                                                        size="large"
                                                        onClick={() =>
                                                            handleDeleteUser(
                                                                row.id,
                                                            )
                                                        }
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </EnhancedTableRow>
                                    );
                                }) || []}
                            </EnhancedTable>
                        </Loader>
                    </Grid>
                </Grid>
                <Fab
                    color="secondary"
                    className={style.addIcon}
                    aria-label="add"
                    onClick={openAddUserDialog}
                >
                    <AddIcon></AddIcon>
                </Fab>
                <Modal
                    className={classes.modal}
                    closeDialog={closeAddUserDialog}
                    openDialog={openDialog}
                >
                    <Grid
                        container
                        direction="column"
                        className={style.addUserModalContainer}
                    >
                        <Grid item>
                            <Typography variant="h1">
                                {t("Add a user")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AddUserForm
                                onSubmit={handleUserAdd}
                                busy={busy}
                                closeDialog={closeAddUserDialog}
                            ></AddUserForm>
                        </Grid>
                    </Grid>
                </Modal>
                <ErrorDialog error={error} reset={resetError} />
            </AppLayout>
        </>
    );
};

export default UserManagement;
