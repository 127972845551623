/**
 * A service that loads and modifies the existing users
 */

import { AxiosError, AxiosResponse } from "axios";
import get from "lodash.get";
import { useEffect } from "react";
import { atom, SetterOrUpdater, useRecoilState } from "recoil";

import { getClient } from "../../utils/api";

interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    accountAdmin: boolean;
}

interface ApiResponse {
    users: User[];
}

interface State {
    users: User[] | null;
    loading: boolean;
    error: Error | AxiosError | null;
}

interface Hook extends State {
    setAdmin: (id: string, value: boolean) => Promise<void>;
    addUser: (user: Partial<User>) => Promise<void>;
    deleteUser: (userId: string) => Promise<void>;
    resetError: () => void;
}

const NAMESPACE = "services:account-users";
const SEARCHABLE_FIELDS = ["id", "firstName", "lastName", "email"];
const INITIAL_STATE = {
    error: null,
    loading: false,
    users: null,
};

let request: Promise<AxiosResponse<ApiResponse>> | null = null;

// The actual state atom
const serviceState = atom<State>({
    default: INITIAL_STATE,
    key: NAMESPACE,
});

const setAdmin = () => (id: string, value: boolean) => {
    // Set Admin
    // eslint-disable-next-line no-console
    console.log(`Set admin of ${id} to ${value}.`);
    return Promise.resolve();
};

/**
 * Filter the users based on search input
 */
const filter = (users: User[] | null, query?: string | void) => {
    if (!users || !query) {
        return users;
    }

    return users.filter((user: User) => {
        const record = SEARCHABLE_FIELDS.map(key =>
            get(user, key)?.toLowerCase(),
        ).join(" ");

        if (record.indexOf(query?.toLowerCase()) >= 0) {
            return true;
        }

        return false;
    });
};

/**
 * Sort the users according to the desired values
 */
const sorted = (
    users: User[] | null,
    sort?: { order: "asc" | "desc"; field: string } | void,
) => {
    if (!users || !sort) {
        return users;
    }

    return users.sort((first: User, last: User) => {
        const firstField = get(first, sort.field);
        const lastField = get(last, sort.field);

        if (firstField < lastField) {
            return sort.order === "asc" ? -1 : 1;
        }

        if (firstField > lastField) {
            return sort.order === "desc" ? -1 : 1;
        }

        return 0;
    });
};

/**
 * Add an account user
 */
const addUser =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (user: Partial<User>): Promise<void> => {
        if (!state.users) {
            return;
        }

        try {
            setState({ ...state, loading: true });
            await getClient().post("/account/user", user);
            const response: AxiosResponse<ApiResponse> = await getClient().get(
                "/account/users",
            );
            setState({
                ...state,
                loading: false,
                users: response.data.users,
            });
        } catch (error) {
            // TODO: Convert to decent logging
            // eslint-disable-next-line no-console
            console.log("Failed");
            setState({
                ...state,
                error,
                loading: false,
            });
        }
    };

/**
 * Delete a user
 */
const deleteUser =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (userId: string) => {
        const before = state.users ? [...state.users] : state.users;

        try {
            setState({
                ...state,
                loading: true,
                users: state.users
                    ? state.users.filter(user => user.id != userId)
                    : state.users,
            });
            await getClient().delete(`/account/user/${userId}`);
        } catch (error) {
            setState({ ...state, error, loading: false, users: before });
            // TODO: Convert to decent logging
            // eslint-disable-next-line no-console
            console.log("Failed");
        }
    };

/**
 * Resets the error state to its default value
 * @param  {State} state
 * @param  {SetterOrUpdater<State>} setState
 */
const resetError = (state: State, setState: SetterOrUpdater<State>) => () => {
    setState({
        ...state,
        error: null,
    });
};

export const useAccountUsersService = (
    query?: string | void,
    sort?: { order: "asc" | "desc"; field: string } | void,
): Hook => {
    const [state, setState] = useRecoilState(serviceState);

    useEffect(() => {
        if (!state.loading && !state.users && !state.error) {
            (async () => {
                // Set loading
                setState({ ...state, loading: true });

                try {
                    if (!request) {
                        request = getClient().get("/account/users");
                    }

                    const response = await request;
                    setState({
                        ...INITIAL_STATE,
                        users: response.data.users,
                    });
                } catch (error) {
                    setState({ ...state, error, loading: false });
                    // TODO: Add error to logger
                    // eslint-disable-next-line no-console
                    console.log(error);
                } finally {
                    request = null;
                }
            })();
        }
    }, [state, setState]);

    return {
        ...state,
        addUser: addUser(state, setState),
        deleteUser: deleteUser(state, setState),
        resetError: resetError(state, setState),
        setAdmin: setAdmin(),
        users: sorted(filter(state.users, query), sort),
    };
};
